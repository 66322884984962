import React, { Component, Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobile } from '@fortawesome/pro-light-svg-icons'

import BannerH4 from '../components/share/banner/bannerH4'
import BannerH6 from '../components/share/banner/bannerH6'
import BrandBanner from '../components/share/brandBanner'
import TypeFormButton from '../components/share/typeFormButton'
import ContentWrapper from '../components/share/contentWrapper'
import FooterBox from '../components/share/footerBox'
import ImageBackground from '../components/share/imageBackground'
import IntroRow from '../components/share/introRow'
import Layout from '../components/layout'
import ProductBoxes from '../components/share/productBoxes'
import SpeakToTeam from '../components/share/speakToTeam'

import customMedia from '../utils/customMedia'

const query = graphql`
  query {
    header: file(relativePath: { eq: "mobileAgent/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerPhone: file(relativePath: { eq: "share/headerPhone.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    mobileCampaigns: file(
      relativePath: { eq: "mobileAgent/mobileCampaigns.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mobileApp: file(relativePath: { eq: "mobileAgent/mobileApp.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    orderTracking: file(relativePath: { eq: "mobileAgent/orderTracking.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    invoice: file(relativePath: { eq: "share/invoice.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    photography: file(relativePath: { eq: "share/photography.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ipad: file(relativePath: { eq: "mobileAgent/ipad.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

class MobileAgentPage extends Component {
  render() {
    return (
      <Layout>
        <StaticQuery
          query={query}
          render={data => (
            <Fragment>
              <ImageBackground
                background={data.header.childImageSharp.fluid}
                opacity="0.6"
                style={{ height: '600px' }}
                skew
              >
                <ContentWrapper>
                  <FontAwesomeIcon
                    icon={faMobile}
                    style={{ width: '25px', height: '25px', marginBottom: 10 }}
                  />
                  <BannerH6 style={{ marginBottom: 20, textAlign: 'start' }}>
                    Built for Agents & Vendors --{' '}
                    <strong>Improved communication and transparency</strong>
                  </BannerH6>
                  <HeaderTitle>
                    Do you spend more time in the office? Or near your phone?
                  </HeaderTitle>

                  <HeaderBody>
                    It’s impossible to ignore the amount mobile phones have
                    changed our lives. A tiny computer in your hands. That now,
                    can take control of your lead generation and marketing
                    campaigns. You’ve got all the power, right in your pocket.
                  </HeaderBody>
                </ContentWrapper>

                <HeaderPhoneImg
                  fluid={data.headerPhone.childImageSharp.fluid}
                  style={{ position: 'absolute' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </ImageBackground>

              <ContentWrapper style={{ alignItems: 'center' }}>
                <BannerH4>
                  Build <strong>an entire campaign</strong> for{' '}
                  <strong>million dollar properties,</strong> in minutes, with
                  team collaboration and vendor inclusion.
                </BannerH4>
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  title="Stay in the loop with your team, and the vendors"
                  image={data.mobileApp.childImageSharp.fluid}
                  descWidth="50%"
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>Real-time collaboration</strong>
                      </Paragraph>
                      <Paragraph>
                        Open up a world of efficient and relevant communication
                        between your entire team and vendors with our
                        system-wide conversation functionality.
                      </Paragraph>
                      <Paragraph>
                        Get proofs signed off faster and invite the vendor to
                        contribute to the conversation. Eliminate nasty mistakes
                        with our fast email proofing and communication system.
                      </Paragraph>
                      <Paragraph>
                        Save artworks before they go to print. With the most up
                        to date proofs only a click away before approval,nd most
                        important of all, accessible anywhere, via web or
                        mobile.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  reverse
                  title="Deliver better customer service to your clients"
                  image={data.orderTracking.childImageSharp.fluid}
                  descWidth="50%"
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>SMS Alerts, emails and notifications.</strong>
                      </Paragraph>
                      <Paragraph>
                        Sellers want to know as much about what’s happening with
                        their property campaign as possible, by being able to
                        access all your campaigns on the go, you’ll be able to
                        provide more transparent and immediate service.
                      </Paragraph>
                      <Paragraph>
                        A happy and educated vendor takes less effort to
                        maintain. Using our alerts you can reduce points of
                        contact and avoid confusion
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ImageBackground
                height="500px"
                opacity="0.5"
                background={data.mobileCampaigns.childImageSharp.fluid}
              >
                <ContentWrapper style={{ margin: '30px 0' }}>
                  <IntroRow
                    title="24/7 Complete Oversight on all your Campaigns"
                    descWidth="50%"
                    content={
                      <Fragment>
                        <Paragraph>
                          <strong>
                            You never know when you’ll need to update a vendor,
                            or need to follow up on an issue.
                          </strong>
                        </Paragraph>
                        <Paragraph>
                          Why should you be limited to business hours or your
                          access to a computer? The most important information
                          about your campaign is available in the palm of your
                          hand or your jacket pocket.
                        </Paragraph>
                        <Paragraph>
                          You’ll save time from sending emails, or waiting for
                          replies. Instant information is made available at your
                          fingertips. The way modern technology should be.
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </ImageBackground>

              <ContentWrapper>
                <IntroRow
                  reverse
                  descWidth="50%"
                  image={data.invoice.childImageSharp.fluid}
                  title="Keep an eye on expenses and avoid nasty surprises."
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Campaign marketing finances can be a difficult
                          conversation between yourself and a vendor.
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        If there is a need for additional marketing, or the
                        situation changes and they need to spend more money
                        there has to be a paper trail right? Not anymore,
                        invoices and expenditures as well as contributions and
                        bills paid across your entire campaign are now available
                        to agents and vendors at a moments notice.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  descWidth="50%"
                  image={data.photography.childImageSharp.fluid}
                  title="Campaign media, dropped straight into your hands"
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Less expired dropbox links, confusion and back and
                          forward email proofs... or even worse printed proofs
                          with handwritten notes.
                        </strong>
                      </Paragraph>
                      <Paragraph>
                        Comment, tag and approve/reject photography and other
                        media directly within your app. Keep the conversations
                        where they matter most and increase accountability and
                        transparency. Less confusion, fewer expenses.
                      </Paragraph>

                      <Paragraph>
                        Photographers can upload their finished product directly
                        into your realhub account giving you{' '}
                        <strong>
                          direct and instant access. To you and the vendors.
                        </strong>
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  reverse
                  descWidth="50%"
                  image={data.ipad.childImageSharp.fluid}
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          The Realhub App is available on iOS today, and no
                          matter what device you or the vendor is using you can
                          access the campaign essentials via any devices web
                          browser.
                        </strong>
                      </Paragraph>
                      <Paragraph>
                        On the go means different things for different people.
                        Some agents carry laptops, some ipads, some just their
                        phones Realhub recognised there’s no one way of
                        operating your business, so the entire platform is
                        developed to accommodate any device.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper style={{ alignItems: 'center' }}>
                <BannerH4>
                  Get <strong>every real estate service</strong> in{' '}
                  <strong>one place</strong>, and save hours communicating and
                  tracking entire campaigns.
                </BannerH4>
                <TypeFormButton />
              </ContentWrapper>
              <ContentWrapper>
                <ProductBoxes />
              </ContentWrapper>
              <ContentWrapper>
                <FlexColumn style={{ alignItems: 'center' }}>
                  <BannerH4>
                    Our <strong>loyal team</strong> of{' '}
                    <strong>Australia’s leading</strong> real estate agencies,
                    working <strong>hand in hand</strong> to help build the{' '}
                    <strong>future of real estate.</strong>
                  </BannerH4>
                  <BannerH6 style={{ marginBottom: 0 }}>
                    Input from the largest and smallest real estate businesses
                    in Australia is helping to build the most effective platform
                    for professional agents.
                  </BannerH6>
                </FlexColumn>
              </ContentWrapper>
              <ContentWrapper>
                <SpeakToTeam />
              </ContentWrapper>
              <ContentWrapper>
                <BrandBanner />
              </ContentWrapper>
              <ContentWrapper direction="row">
                <FooterBox name="migration" />

                <FooterBox name="feetUp" />
              </ContentWrapper>
            </Fragment>
          )}
        />
      </Layout>
    )
  }
}

export default MobileAgentPage

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 680px;
    font-size: 53px;
  `};
`

const HeaderPhoneImg = styled(Img)`
  position: absolute;
  overflow: hidden;
  width: 200px;
  height: 200px;
  bottom: -80px;
  right: 0;

  ${customMedia.between('mobile', 'tablet')`
    width: 350px;
    height: 350px;
    bottom: -80px;
    right: 20px;
  `};

  ${customMedia.greaterThan('desktop')`
    width: 350px;
    height: 350px;
    bottom: -120px;
    right: 0px;
  `};
`

const HeaderBody = styled.p`
  font-size: 14px;
  font-weight: 300;
  font-family: Open Sans;
  line-height: 1.5;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 450px;
  `};
`

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
